/* pure-hidden-xs */
@media screen and (max-width:567px) {
    .pure-visible-sm{display:none}
    .pure-visible-md{display:none}
    .pure-visible-lg{display:none}
    .pure-visible-xl{display:none}
    .pure-hidden-xs{display:none}
}
/* pure-hidden-sm */
@media screen and (min-width:568px) and (max-width:767px) {
    .pure-visible-xs{display:none}
    .pure-visible-md{display:none}
    .pure-visible-lg{display:none}
    .pure-visible-xl{display:none}
    .pure-hidden-sm{display:none}
}
/* pure-hidden-md */
@media screen and (min-width:768px) and (max-width:1023px) {
    .pure-visible-xs{display:none}
    .pure-visible-sm{display:none}
    .pure-visible-lg{display:none}
    .pure-visible-xl{display:none}
    .pure-hidden-md{display:none}
}
/* pure-hidden-lg */
@media screen and (min-width:1024px) and (max-width:1279px) {
    .pure-visible-xs{display:none}
    .pure-visible-sm{display:none}
    .pure-visible-md{display:none}
    .pure-visible-xl{display:none}
    .pure-hidden-lg{display:none}
}
/* pure-hidden-xl */
@media screen and (min-width:1280px) {
    .pure-visible-xs{display:none}
    .pure-visible-sm{display:none}
    .pure-visible-md{display:none}
    .pure-visible-lg{display:none}
    .pure-hidden-xl{display:none}
}