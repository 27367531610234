label,
input,
textarea,
select,
input[type='radio'],
input[type='checkbox'],
.input-prefix,
.input-suffix {
  line-height: normal;
}
[type='text'],
[type='password'],
[type='search'],
[type='email'],
[type='url'],
[type='number'],
[type='tel'],
textarea,
select,
input[type='radio'],
input[type='checkbox'],
.input-prefix,
.input-suffix {
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  background-color: #fff;
  color: #565a5c;
  cursor: text;
  -webkit-appearance: none;
}
input,
textarea,
.input-prefix,
.input-suffix {
  font-family: Circular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.input-prefix::-webkit-input-placeholder,
.input-suffix::-webkit-input-placeholder {
  color: #82888a;
}
input::-moz-placeholder,
textarea::-moz-placeholder,
.input-prefix::-moz-placeholder,
.input-suffix::-moz-placeholder {
  color: #82888a;
}
input:-ms--placeholder,
textarea:-ms--placeholder,
.input-prefix:-ms--placeholder,
.input-suffix:-ms--placeholder {
  color: #82888a;
}

[type='text'],
[type='password'],
[type='search'],
[type='email'],
[type='url'],
[type='number'],
[type='tel'],
textarea,
select,
input[type='radio'],
input[type='checkbox'] {
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
.focus[type='text'],
.focus[type='password'],
.focus[type='search'],
.focus[type='email'],
.focus[type='url'],
.focus[type='number'],
.focus[type='tel'],
textarea.focus,
select.focus,
input.focus[type='radio'],
input.focus[type='checkbox'],
[type='text']:focus,
[type='password']:focus,
[type='search']:focus,
[type='email']:focus,
[type='url']:focus,
[type='number']:focus,
[type='tel']:focus,
textarea:focus,
select:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  border-color: #aaa;
}
.invalid[type='text'],
.invalid[type='password'],
.invalid[type='search'],
.invalid[type='email'],
.invalid[type='url'],
.invalid[type='number'],
.invalid[type='tel'],
textarea.invalid,
select.invalid,
input.invalid[type='radio'],
input.invalid[type='checkbox'],
[type='text']:invalid,
[type='password']:invalid,
[type='search']:invalid,
[type='email']:invalid,
[type='url']:invalid,
[type='number']:invalid,
[type='tel']:invalid,
textarea:invalid,
select:invalid,
input[type='radio']:invalid,
input[type='checkbox']:invalid {
  border-color: #ffb400;
  background-color: #fff8e5;
}
.valid[type='text'],
.valid[type='password'],
.valid[type='search'],
.valid[type='email'],
.valid[type='url'],
.valid[type='number'],
.valid[type='tel'],
textarea.valid,
select.valid,
input.valid[type='radio'],
input.valid[type='checkbox'] {
  border-color: #3fb34f;
}
[type='text']:focus,
[type='password']:focus,
[type='search']:focus,
[type='email']:focus,
[type='url']:focus,
[type='number']:focus,
[type='tel']:focus,
textarea:focus,
select:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: none;
}
.disabled[type='text'],
.disabled[type='password'],
.disabled[type='search'],
.disabled[type='email'],
.disabled[type='url'],
.disabled[type='number'],
.disabled[type='tel'],
textarea.disabled,
select.disabled,
input.disabled[type='radio'],
input.disabled[type='checkbox'],
[disabled][type='text'],
[disabled][type='password'],
[disabled][type='search'],
[disabled][type='email'],
[disabled][type='url'],
[disabled][type='number'],
[disabled][type='tel'],
textarea[disabled],
select[disabled],
input[disabled][type='radio'],
input[disabled][type='checkbox'],
fieldset[disabled] [type='text'],
fieldset[disabled] [type='password'],
fieldset[disabled] [type='search'],
fieldset[disabled] [type='email'],
fieldset[disabled] [type='url'],
fieldset[disabled] [type='number'],
fieldset[disabled] [type='tel'],
fieldset[disabled] textarea,
fieldset[disabled] select,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  background-color: #edefed;
  color: #82888a;
  cursor: default;
}
[disabled][type='text'],
[disabled][type='password'],
[disabled][type='search'],
[disabled][type='email'],
[disabled][type='url'],
[disabled][type='number'],
[disabled][type='tel'],
textarea[disabled],
select[disabled],
input[disabled][type='radio'],
input[disabled][type='checkbox'],
fieldset[disabled] [type='text'],
fieldset[disabled] [type='password'],
fieldset[disabled] [type='search'],
fieldset[disabled] [type='email'],
fieldset[disabled] [type='url'],
fieldset[disabled] [type='number'],
fieldset[disabled] [type='tel'],
fieldset[disabled] textarea,
fieldset[disabled] select,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  pointer-events: none;
}
[type='text'],
[type='password'],
[type='search'],
[type='email'],
[type='url'],
[type='number'],
[type='tel'],
textarea,
select,
fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}
label {
  display: block;
  padding-top: 9px;
  padding-bottom: 8px;
}
.label-large {
  padding-top: 11px;
  padding-bottom: 11px;
}
.label-inline {
  display: inline-block;
  margin-right: 10px;
}
textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  resize: vertical;
  line-height: inherit;
}
select {
  -webkit-appearance: none;
  background: white !important;
}
select::-ms-expand {
  display: none;
}
// .select {
//     position: relative;
//     display: inline-block;
//     vertical-align: bottom
// }
// .select select {
//     padding-top: 8px;
//     padding-bottom: 8px;
//     padding-right: 2em
// }
// .select:before {
//     content: '\25bc';
//     position: absolute;
//     pointer-events: none;
//     color: #82888a;
//     top: 0;
//     bottom: 1px;
//     padding-top: 0.7em;
//     line-height: 1;
//     right: 0;
//     width: 2em;
//     text-align: center;
//     transform: scale(0.84, 0.42);
//     -webkit-transform: scale(0.84, 0.42);
//     -moz-transform: scale(0.84, 0.42);
//     -ms-transform: scale(0.84, 0.42);
//     -o-transform: scale(0.84, 0.42);
//     -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand')";
//     filter: "progid: DXImageTransform.Microsoft.Matrix(M11=0.84, M12=0, M21=0, M22=0.42, SizingMethod='auto expand')";
// }
// .select-block {
//     display: block;
//     width: 100%
// }
// .select-large {
//     font-size: 16px
// }
// .select-large select {
//     padding-top: 10px;
//     padding-bottom: 10px
// }
// @-moz-document url-prefix() {
//     .select:before {
//         display: none
//     }
//     .select select {
//         padding-top: 7px;
//         padding-bottom: 7px;
//         padding-right: 0
//     }
//     .select-large select {
//         padding-top: 9px;
//         padding-bottom: 9px
//     }
// }
// .ie .select:before {
//     display: none
// }
// .ie .select select {
//     padding-top: 7px;
//     padding-bottom: 7px;
//     padding-right: 0
// }
// .ie .select-large select {
//     padding-top: 9px;
//     padding-bottom: 9px
// }
input[type='radio'],
input[type='checkbox'] {
  position: relative;
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  margin-bottom: -0.25em;
  margin-right: 2px;
  vertical-align: top;
}
input[type='radio'] {
  border-radius: 1em;
}
input[type='radio']:checked:before {
  content: '';
  position: absolute;
  height: 0.45em;
  width: 0.45em;
  border-radius: 0.45em;
  top: 50%;
  left: 50%;
  margin-top: -0.225em;
  margin-left: -0.225em;
  background-color: #ff5a5f;
}
input[type='checkbox']:checked {
  border: 1px solid #666;
  border-bottom-width: 2px;
}
input[type='checkbox']:checked:before {
  content: '\f12f';
  position: absolute;
  font-size: 1em;
  text-align: center;
  // color: #ff5a5f;
  color: #666;
  line-height: 0.6;
  font-family: dkrm-glyphs;
  // content: "\2713";
  // position: absolute;
  // font-size: 0.85em;
  // left: 1px;
  // text-align: center;
  // width: 1.25em;
  // color: #ff5a5f
}
.input-addon {
  display: table;
  width: 100%;
}
.input-stem {
  display: table-cell;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-prefix + .input-stem {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
// .ie .input-prefix.select select {
//     padding-right: 0;
//     display: table-cell;
//     outline: 0px solid #000
// }
.input-prefix,
.input-suffix {
  display: table-cell;
  background-color: #edefed;
  width: 1px;
  white-space: nowrap;
  vertical-align: top;
}
.input-prefix.btn,
.input-suffix.btn {
  padding-top: 7px;
  padding-bottom: 6px;
}
// .input-prefix.select select,
// .input-suffix.select select {
//     border: 0;
//     background: inherit;
//     width: auto;
//     padding: 0 1.0em 0 0
// }
.input-prefix {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.input-suffix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.ie .input-suffix.btn {
  border: none;
  padding-left: 10px;
  padding-right: 10px;
}
.ie .input-suffix.btn:focus,
.ie .input-suffix.btn:hover {
  border: none;
}
.input-large {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.input-resize-horiz {
  resize: horizontal;
}
.input-resize-both {
  resize: both;
}
.input-resize-none {
  resize: none;
}

// Normally used together with .btn-no-left-radius
// ______________________
// | ....       | submit|
// ----------------------
// .input-join-to-right .btn-no-left-radius

.input-join-to-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}
@media (max-width: 767px) {
  // .select-large,
  .input-large {
    font-size: 15px;
  }
}
