
.modal-container {
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-transform: translate3d(0, 0, 0);
    background-color: #f5f5f5;
    background-color: rgba(0, 0, 0, 0.15)
}
.modal--transparent{
    background-color: inherit;
}
.modal {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s
}
.modal-table {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%
}
.modal-cell {
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    padding: 50px
}
.modal-transitions.transition-enter {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s
}
.modal-transitions.transition-enter-active {
    opacity: 1;
    filter: alpha(opacity=100)
}
.modal-transitions.transition-leave {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s
}
.modal-transitions.transition-leave-active {
    opacity: 0;
    filter: alpha(opacity=0)
}
.modal-content {
    position: relative;
    overflow: hidden;
    // border-radius: 2px;
    // background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 520px
}
.modal-content:focus {
    outline: none
}
.modal-close {
    padding: 8px;
    margin-right: -8px;
    margin-top: -8px
}
.modal-open {
    overflow: hidden
}
.modal-absolute {
    position: absolute
}
@media (max-width: 767px) {
    .modal {
        background: none
    }
    .modal-header {
        text-align: center
    }
    .modal-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%
    }
    .modal-table,
    .modal-cell {
        display: block
    }
    .modal-cell {
        padding: 0
    }
    .modal-content {
        max-width: none;
        height: 100%;
        border-radius: 0;
        overflow: auto
    }
}