$w-caret: 4px;
$w-caret-big: 8px;

.caret{
  display: inline-block;
  width: 0;
  height: 0;
  text-indent: -99999px;
  vertical-align: middle;
  border: $w-caret solid transparent;
  &.caret-down{
    border-top-color: inherit;
  }
  &.caret-up{
    border-bottom-color: inherit;
  }
}

.caret-up--white{
  border-bottom-color: white;
}

.caret-popover{
  border-width: $w-caret-big; //make it bigger

  margin-top: -2*$w-caret-big + 1;
  right: 20px;
  position: absolute;

  &:after{
    content: '';

    @extend .caret;
    right: -10px;
    border-width: $w-caret-big + 2;
    margin-left: -1 * ($w-caret-big + 2);
    margin-top: -1 * ($w-caret-big + 4);

    position: absolute;
    z-index: -1;

    // border-bottom-color: #e5e5e5;
    border-bottom-color: rgba(0, 0, 0, 0.15);
  }
}