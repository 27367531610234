/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * README:
 *
 * Z-index Guidelines
 *   navigation: 1x
 *   popover: 2x
 *   modal: 3x
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

@import 'uikit-constants';

* {
  outline: none;
  -webkit-font-smoothing: antialiased;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Typography  (part i)
 * This define the 'default' style, thus are meant to be overriden
 *
 *   When setting a font-family in your project, be sure to check out the section
 *   on using Grids with your font-family.
 *   http://purecss.io/grids/
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

body,
.pure-g,
.pure-g [class*='pure-u'] {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  @extend .fs3;
  color: #333;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  @extend .fs1;
  letter-spacing: -1px;
  line-height: 1.25;
}

h2 {
  @extend .fs2;
  letter-spacing: -0.5px;
  line-height: 1.25;
}

h3 {
  @extend .fs3;
  line-height: 1.25;
}

p {
  margin-top: 0.1em;
}

.ff {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}
.fsmt-n {
  -webkit-font-smoothing: auto;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Link
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

a,
.link {
  background: transparent;
  color: $c-secondary;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  &:hover,
  &:focus {
    color: $c-secondary;
    text-decoration: underline;
  }
  &:active,
  &:hover {
    outline: 0;
  }
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Line
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

hr {
  border: 0;
  border-top: 1px solid #dce0e0;
}

.hr-right {
  border-right: 1px solid #dce0e0;
}
.hr-left {
  border-left: 1px solid #dce0e0;
}

.hr-black {
  border-top: 1px solid #333;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Button
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-button';
@import 'uikit-component-button-contextual';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Page Container (width constraints, breakpoint , etc)
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.page-container,
.page-container-responsive {
  margin-left: auto;
  margin-right: auto;
  padding-left: 12.5px;
  padding-right: 12.5px;
}
.page-container:before,
.page-container-responsive:before,
.page-container:after,
.page-container-responsive:after {
  content: ' ';
  display: table;
}
.page-container:after,
.page-container-responsive:after {
  clear: both;
}
@media (min-width: 768px) {
  .page-container,
  .page-container-responsive {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.page-container {
  width: 1045px;
}
.page-container-responsive {
  width: auto;
}
@media (min-width: 768px) {
  .page-container-responsive {
    width: 745px;
  }
}
@media (min-width: 1100px) {
  .page-container-responsive {
    width: 1045px;
  }
}

.page-container-full {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Pagination
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.pagination {
  margin: 6px 0;
  line-height: 20px;
}
.pagination ul {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 0;
}
.pagination li {
  display: inline;
}
.pagination li:first-child a {
  margin-left: 0;
}
.pagination a {
  float: left;
  padding: 5px 6px;
  margin-left: 2px;
  text-decoration: none;
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: #fff;
  color: #82888a;
}
.pagination a:hover {
  background-color: #edefed;
}
.pagination .active a {
  color: $c-primary;
  // cursor: default;
  border: 1px solid #dce0e0;
  padding: 4px 6px;
  font-weight: bold;
  &:hover {
    background-color: #fff;
  }
}
.pagination .disabled {
  opacity: 0.3;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  background-color: transparent;
  cursor: default;
}
.pagination .gap span,
.pagination .gap a,
.pagination .gap a:hover {
  float: left;
  padding: 2px 5px;
  border: 0;
  background: transparent;
  box-shadow: 0 0 0 #000;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Input, Label, Textarea, Select
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

@import 'uikit-component-input';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Caret
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-caret';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Icon
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-icon';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Panel, Alert, Modal
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.panel-close,
.alert-close,
.modal-close {
  float: right;
  cursor: pointer;
  font-size: 2em;
  line-height: 0.45;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  color: #cacccd;
}
.panel-close:before,
.alert-close:before,
.modal-close:before {
  content: '\00d7';
}
.panel-close:hover,
.alert-close:hover,
.modal-close:hover,
.panel-close:focus,
.alert-close:focus,
.modal-close:focus {
  color: #b0b3b5;
  text-decoration: none;
}
.panel-close:not(:empty):before,
.alert-close:not(:empty):before,
.modal-close:not(:empty):before {
  display: none;
}

/**
 * Panel
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.panel {
  border: 1px solid #dce0e0;
  background-color: #fff;
  border-radius: 0;
}
.panel-primary {
  border: 1px solid $c-primary;
  background-color: #fff;
  border-radius: 0;
}
// .panel-quote {
//     position: relative;
//     margin-left: 10px
// }
// .panel-quote:before {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     left: -10px;
//     top: 15px;
//     right: auto;
//     border: 10px solid transparent;
//     border-left: 0;
//     border-right-color: #dce0e0
// }
// .panel-quote:after {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     left: -9px;
//     top: 16px;
//     right: auto;
//     border: 9px solid transparent;
//     border-left: 0;
//     border-right-color: #fff
// }
// .panel-quote.panel-dark:before,
// .panel-quote.panel-header:before {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     left: -10px;
//     top: 15px;
//     right: auto;
//     border: 10px solid transparent;
//     border-left: 0;
//     border-right-color: #dce0e0
// }
// .panel-quote.panel-dark:after,
// .panel-quote.panel-header:after {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     left: -9px;
//     top: 16px;
//     right: auto;
//     border: 9px solid transparent;
//     border-left: 0;
//     border-right-color: #edefed
// }
// .panel-quote-right {
//     position: relative;
//     margin-right: 10px;
//     margin-left: 0
// }
// .panel-quote-right:before {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     right: -10px;
//     top: 15px;
//     left: auto;
//     border: 10px solid transparent;
//     border-right: 0;
//     border-left-color: #dce0e0
// }
// .panel-quote-right:after {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     right: -9px;
//     top: 16px;
//     left: auto;
//     border: 9px solid transparent;
//     border-right: 0;
//     border-left-color: #fff
// }
// .panel-quote-right.panel-dark:before,
// .panel-quote-right.panel-header:before {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     right: -10px;
//     top: 15px;
//     left: auto;
//     border: 10px solid transparent;
//     border-right: 0;
//     border-left-color: #dce0e0
// }
// .panel-quote-right.panel-dark:after,
// .panel-quote-right.panel-header:after {
//     content: "";
//     display: inline-block;
//     position: absolute;
//     right: -9px;
//     top: 16px;
//     left: auto;
//     border: 9px solid transparent;
//     border-right: 0;
//     border-left-color: #edefed
// }
.panel-header,
.panel-body,
ul.panel-body > li,
ol.panel-body > li,
.panel-footer {
  position: relative;
  margin: 0;
  padding: 20px;
  border-top: 1px solid #dce0e0;
}
.panel-header + .panel-body,
.panel-body + .panel-body,
ul.panel-body > li + .panel-body,
ol.panel-body > li + .panel-body,
.panel-footer + .panel-body {
  border-top: none;
}
.panel-header:first-child,
.panel-body:first-child,
ul.panel-body > li:first-child,
ol.panel-body > li:first-child,
.panel-footer:first-child {
  border-top: 0;
}
ul.panel-condensed > li,
ol.panel-condensed > li,
.panel-condensed {
  padding: 14px;
}
.panel-header {
  color: #565a5c;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dce0e0;
}
.panel-header-scroll {
  position: relative;
}
.panel-header-scroll:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.06);
  clip: rect(4px, auto, 20px, auto);
}
.panel-image {
  position: relative;
}
a.panel-image {
  display: block;
}
.panel-body {
  position: relative;
}
.panel-body + .panel-body:before {
  content: '';
  position: absolute;
  top: -1px;
  height: 1px;
  left: 20px;
  right: 20px;
  border-top: 1px solid #dce0e0;
  border-color: rgba(220, 224, 224, 0.5);
}
.panel-body:first-child:before,
.panel-body.panel-dark:before,
.panel-body.panel-header:before {
  display: none;
}
.panel-body > *:first-child {
  margin-top: 0;
}
.panel-body > *:last-child {
  margin-bottom: 0;
}
ul.panel-body,
ol.panel-body {
  padding: 0;
  list-style: none;
}
ul.panel-body > li,
ol.panel-body > li {
  position: relative;
}
ul.panel-body > li + .panel-body:before,
ol.panel-body > li + .panel-body:before {
  content: '';
  position: absolute;
  top: -1px;
  height: 1px;
  left: 20px;
  right: 20px;
  border-top: 1px solid #dce0e0;
  border-color: rgba(220, 224, 224, 0.5);
}
ul.panel-body > li:first-child:before,
ul.panel-body > li.panel-dark:before,
ul.panel-body > li.panel-header:before,
ol.panel-body > li:first-child:before,
ol.panel-body > li.panel-dark:before,
ol.panel-body > li.panel-header:before {
  display: none;
}
ul.panel-body > li > *:first-child,
ol.panel-body > li > *:first-child {
  margin-top: 0;
}
ul.panel-body > li > *:last-child,
ol.panel-body > li > *:last-child {
  margin-bottom: 0;
}
.panel-body-scroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.panel-footer {
  text-align: right;
}
.panel-dark,
.panel-header {
  background-color: #edefed;
}
.panel-lighter {
  background-color: #f5f5f5;
}
.panel-light {
  background-color: #fff;
}
@media (max-width: 767px) {
  .panel-header {
    font-size: 15px;
  }
}

/**
 * Alert
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.alert {
  padding: 15px;
  padding-right: 30px;
  text-align: center;
  position: relative;
  border-radius: 2px;
}
.alert .alert-close {
  position: absolute;
  right: 15px;
  text-decoration: none;
}
.alert .alert-icon {
  margin-right: 20px;
  display: inline-block;
  color: #007a87;
  -webkit-transform: scale(2.4) translateY(-0.4px);
  -ms-transform: scale(2.4) translateY(-0.4px);
  transform: scale(2.4) translateY(-0.4px);
}
.alert-block,
.alert-header.panel-header {
  text-align: left;
  padding: 25px;
  padding-right: 40px;
}
.alert-block.alert-with-icon,
.alert-with-icon.alert-header.panel-header {
  min-height: 100px;
  padding-left: 110px;
}
.alert-block.alert-with-icon .alert-icon,
.alert-with-icon.alert-header.panel-header .alert-icon {
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  margin-right: 0;
  display: block;
  position: absolute;
  font-size: 67px;
  top: 14px;
  left: 24.5px;
}
.alert-block .alert-close,
.alert-header.panel-header .alert-close {
  right: 25px;
}
.alert-header.panel-header {
  text-transform: none;
  padding: 15px;
  border-radius: 0;
}
.alert-header.panel-header.alert-with-icon {
  min-height: 55px;
  padding-left: 76px;
}
.alert-header.panel-header.alert-with-icon .alert-icon {
  font-size: 40px;
  top: 50%;
  left: 20px;
  margin-top: -25.5px;
}
.alert {
  background-color: #c2e4e7;
}
.alert .alert-link {
  color: #007a87;
}
.alert .alert-icon {
  color: #4cb0bb;
}
.alert .alert-close {
  color: #007a87;
}
.alert .alert-close:hover,
.alert .alert-close:focus {
  color: #004c54;
}
.alert-success {
  background-color: #effffb;
}
.alert-success .alert-link {
  color: #3fb34f;
}
.alert-success .alert-icon {
  color: #8bd275;
}
.alert-success .alert-close {
  color: #3fb34f;
}
.alert-success .alert-close:hover,
.alert-success .alert-close:focus {
  color: #328d3e;
}
.alert-danger,
.alert-error {
  background-color: #ffd1c4;
}
.alert-danger .alert-link,
.alert-error .alert-link {
  color: #b2725f;
}
.alert-danger .alert-icon,
.alert-error .alert-icon {
  color: #e4a593;
}
.alert-danger .alert-close,
.alert-error .alert-close {
  color: #b2725f;
}
.alert-danger .alert-close:hover,
.alert-danger .alert-close:focus,
.alert-error .alert-close:hover,
.alert-error .alert-close:focus {
  color: #965a48;
}

.alert-warning {
  color: #9f6000;
  background-color: #feefb3;
}

.alert-warning .alert-icon {
  color: #9f6000;
}

.alert-warning .alert-close {
  color: #a38e3e;
}

.alert-wrapper-success {
  color: #03a87c;
}

/**
 * Modal
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-transform: translate3d(0, 0, 0);
  background-color: #f5f5f5;
  background-color: rgba(0, 0, 0, 0.15);
}
.modal {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.modal-table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}
.modal-cell {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: 50px;
}
.modal-transitions.transition-enter {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.modal-transitions.transition-enter-active {
  opacity: 1;
  filter: alpha(opacity=100);
}
.modal-transitions.transition-leave {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.modal-transitions.transition-leave-active {
  opacity: 0;
  filter: alpha(opacity=0);
}
.modal-content {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 520px;
}
.modal-content:focus {
  outline: none;
}
.modal-close {
  padding: 8px;
  margin-right: -8px;
  margin-top: -8px;
}
.modal-open {
  overflow: hidden;
}
.modal-absolute {
  position: absolute;
}
@media (max-width: 767px) {
  .modal {
    background: none;
  }
  .modal-header {
    text-align: center;
  }
  .modal-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .modal-table,
  .modal-cell {
    display: block;
  }
  .modal-cell {
    padding: 0;
  }
  .modal-content {
    max-width: none;
    height: 100%;
    border-radius: 0;
    overflow: auto;
  }
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Component: Signup
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.decorative-input {
  background-image: url(https://a2.muscache.com/airbnb/static/signinup/text-field-icons-9c18e4e23864cb33a8f0f7c32c0b86ee.png);
  background-repeat: no-repeat;
  background-position: right 11px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  height: 40px;
  padding: 10px;
  width: 100% !important;
}
.decorative-input[type='email'] {
  background-position: right -33px;
}
.decorative-input[type='password'],
.decorative-input.otp-input,
.decorative-input.recaptcha_response_field {
  background-position: right -77px;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Component: Card
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.card {
  position: relative;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.card--no-top-shadow {
  z-index: 0;
  &:before {
    content: '';
    height: 7px;
    width: 100%;
    position: absolute;
    background-color: inherit;
    top: -7px;
    z-index: 1;
  }
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Component: Price
 * The size of this component depends on the font size of .price
 * Thus, if we need to make it bigger/smaller, just add font sizing class such as .fs1, .fs2, etc...
 * This is how to structure HTML for price
 *
 *  .price
 *    [role='currency']
 *    [role='price']
 *    [role='instant']
 *
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-price';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Component: Loading
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-loading';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Component: Contextual layer
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-contextual-layer';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Menu
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-menu';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Modal --> deprecated?
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-modal';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Table
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@import 'uikit-component-table';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Typography (part ii)
 *
 * This folows the 'default' style convention
 * but are meant OVERRIDE existing
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

.fs-single {
  font-size: 32px;
}

.fs1 {
  font-size: 24px;
}
.fs2 {
  font-size: 19px;
}
.fs3 {
  font-size: 15px;
}
.fs4 {
  font-size: 13px;
}

.ff-alt {
  font-family: 'Circular', 'Helvetica Neue';
  font-weight: 600;
}

@import 'uikit-component-typography-weight';
@import 'uikit-component-typography-color';

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Mark is used to highlight text search
 *
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
mark {
  background-color: #99dfe3;
}
