.btn.btn-default {
  color: #333;
  background-color: #FFF;
  border: 1px solid #ccc;
  padding: 6px 8px;
  text-shadow: 0 1px #fff;

  @media only screen and (min-width: 1300px) {
    padding: 6px 13px;
  }

  &:hover, &:focus{
    background: #f7f7f7;
    border-color: #ccc #c2c2c2 #b8b8b8;
    box-shadow: inset 0 0.5px 1px rgba(38,41,44,0.1);
  }
  &.active, &:active{
    border-color: #b6b6b6;
    background: #e5e5e5;
    box-shadow: inset 0 1px 2px rgba(38,41,44,0.1);
    font-weight: bold;
    text-shadow: none;
  }
}

.btn.btn-dropdown {
  color: #333;
  min-width: 80px;
  background-color: #f0f0f0;
  background-image: linear-gradient(#fff, #f0f0f0);
  border: 1px solid #ccc;
  padding: 6px 8px;
  text-shadow: 0 1px #fff;

  @media only screen and (min-width: 1300px) {
    padding: 6px 13px;
  }

  &:hover, &.active, &:active {
    border-color: #ccc #c2c2c2 transparent;
    background-image: linear-gradient(transparent 0%, #fff 100%);
  }
}


.btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}

.btn-group .btn {
  font-size: 12px;
  float: left;
  @media only screen and (min-width: 1300px) {
    min-width: 80px;
  }
  border-radius: 0;
  position: relative;
}

.btn-group .btn:first-child {
  border-radius: 6px 0 0 6px;
}

.btn-group .btn:last-child {
  border-radius: 0 6px 6px 0;
}

.btn-group .btn:hover,
.btn-group .btn:focus,
.btn-group .btn.active,
.btn-group .btn:active {
  z-index: 1
}

.btn-group .btn+.btn {
  margin-left: -2px
}

.btn-group+.btn-group {
  margin-left: 4px
}

.toolbar-element {
  display: inline-block;
}

.toolbar-element:not(:first-child) {
  @media only screen and (min-width: 1300px) {
    margin-left: 10px;
  }
}