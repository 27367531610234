@import 'uikit-constants';

// Button Structure
.btn {
  display: inline-block;
  margin-bottom: 0;
  border-radius: 2px;
  border: 1px solid;
  text-align: center;
  vertical-align: middle;
  font-weight: 500; // adjusted least for btn-large. might not be correct for btn-small
  letter-spacing: 0.4px; // adjusted lest for btn-large. might not be correct for btn-small
  line-height: 1.43;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
}

// Button Sizing
.btn {
  padding: 7px 21px;
  font-size: 14px;
}

.btn-small {
  padding: 4px 12px;
  font-size: 12px;
}

.btn-large {
  padding: 9px 12px;
  font-size: 16px;
}

.btn-x-large {
  padding: 10px 12px;
  font-size: 19px;
}

// Button Style
.btn {
  border-color: #c4c4c4;
  background: white;
  color: #565a5c;
  &:hover, &:focus{
    text-decoration: none;
    border-color: #aaa;
    color: #565a5c;
    text-decoration: none;
  }
  &.active, &:active{
    background: #f2f2f2;
  }
}

.btn.btn-primary-alt {
  border-color: $c-secondary;
  background-color: $c-secondary;
  border-bottom-color: $c-secondary--active;
  color: #fff;
  &:hover, &:focus{
    border-color: $c-secondary--focus;
    background-color: $c-secondary--focus;
    border-bottom-color: #fa0008;
    color: #fff;
  }
  &.active, &:active{
    border-color: $c-secondary--active;
    background-color: $c-secondary--active;
    color: #fff;
  }
}

.btn.btn-primary {
  border-color: $c-primary;
  background-color: $c-primary;
  border-bottom-color: $c-primary--active;
  color: #fff;
  &:hover, &:focus{
    border-color: $c-primary--focus;
    background-color: $c-primary--focus;
    border-bottom-color: $c-primary--border;
    color: #fff;
  }
  &.active, &:active{
    border-color: $c-primary--active;
    background-color: $c-primary--active;
    color: #fff;
  }
}

.btn.btn-facebook {
  border-color: #3b5998;
  background-color: #3b5998;
  border-bottom-color: #3b5998;
  color: #fff;
  &:hover, &:focus{
    border-color: #5672AD;
    background-color: #5672AD;
    border-bottom-color: #3b5998;
    color: #fff;
  }
  &.active, &:active{
    border-color: #3b5998;
    background-color: #3b5998;
    color: #fff;
  }
}

.btn.btn-success {
  border-color: $c-green;
  background-color: $c-green;
  border-bottom-color: $c-green--active;
  color: #fff;
  &:hover, &:focus{
    border-color: $c-green--focus;
    background-color: $c-green--focus;
    border-bottom-color: $c-green--border;
    color: #fff;
  }
  &.active, &:active{
    border-color: $c-green--active;
    background-color: $c-green--active;
    color: #fff;
  }
}

.btn.btn-mattress {
  border-color: #083D77;
  background-color: #083D77;
  border-bottom-color: #073262;
  color: #f5f5f5;
  &:hover, &:focus{
    border-color: #073262;
    background-color: #1E4E83;
    border-bottom-color: #073262;
    color: #f5f5f5;
  }
  &.active, &:active{
    border-color: #073262;
    background-color: #1E4E83;
    color: #f5f5f5;
  }
}

.disabled.btn, [disabled].btn, fieldset[disabled] .btn {
  opacity: 0.35;
  filter: alpha(opacity=35);
  cursor: default;
}

.btn-no-radius {
  border-radius: 0;
}

.btn-no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// Button Helper

.btn-block {
  display: block;
  white-space: normal;
  width: 100%;
}

.btn.btn-grey, .btn.btn-icon-grey {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
  border: 0px !important;
  background-color: $c-default;
  color: $c-muted;
  position: relative;
  border-radius: 5px;

  &:before {
    position: absolute;
    left: -4px;
    top: -4px;
    right: -4px;
    bottom: -4px;
    content: '';
    visibility: hidden;
    border: 2px solid #AAAAAA;
    background: transparent;
    border-radius: 5px;
  }

  &.active {
    color: #555 !important;
  }
  &:hover, &:focus{
    color: $c-muted;
    background-color: lighten($c-muted, 40%) !important;
  }
  &:active{
    background-color: lighten($c-muted, 40%) !important;
    &:before {
      visibility: visible;
    }
  }
}

.btn.btn-icon-grey {
  width: 48px !important;
  height: 48px !important;
  border-radius: 50% !important;
  cursor: pointer;

  margin-top: -3px;

  &:before {
    border-radius: 50% !important;
  }

  img {
    width: 28px;
    height: 28px;
    z-index: 0;
    &.active {
      display: none;
    }
    &.default {
      display: block;
    }
  }
  &.active{
    img.active {
      display: block !important;
    }
    img.default {
      display: none !important;
    }
  }
}
