@import 'uikit-component-caret';

/**
 * This is how to structure HTML for contextual layer (popover)
 *
 *   .contextual-layer-positioning-below-right (choose position)
 *     .contextual-layer
 *       .contextual-layer-border (this is the border style)
 *         .contextual-layer-caret (insert caret)
 *         <any content, eg. menu>
 *
 * If we want to customize the style,
 * just need to create another class similar to .contextual-layer-border
 */


%contextual-layer-positioning{
  z-index:10;
  position: absolute;
  min-width: 400px;
}

// 1.
.contextual-layer-positioning-below-right{
  @extend %contextual-layer-positioning;
  right: 0px;
  .contextual-layer{
    right: 0px;
  }
  .contextual-layer-caret{
    right: 20px;
  }
}
.contextual-layer-positioning-below-left{
  @extend %contextual-layer-positioning;
  left: 0px;
  .contextual-layer{
    left: 0px;
  }
  .contextual-layer-caret{
    left: 20px;
  }
}

// 2.
.contextual-layer{
  padding-top: 10px;
  position: absolute;
}

// 3.
.contextual-layer-border{
  -webkit-background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 3px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
}

// 4.
.contextual-layer-caret{
  @extend .caret;
  @extend .caret-up--white;
  @extend .caret-popover;
}
