
.loading-alt {
  position: relative
}

.loading-alt:before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    // z-index: 1;
    content: " ";
    background: url("data:image/gif;base64,R0lGODlhKwALAPEAAP///5WVlcrKypWVlSH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAKwALAAACMoSOCMuW2diD88UKG95W88uF4DaGWFmhZid93pq+pwxnLUnXh8ou+sSz+T64oCAyTBUAACH5BAkKAAAALAAAAAArAAsAAAI9xI4IyyAPYWOxmoTHrHzzmGHe94xkmJifyqFKQ0pwLLgHa82xrekkDrIBZRQab1jyfY7KTtPimixiUsevAAAh+QQJCgAAACwAAAAAKwALAAACPYSOCMswD2FjqZpqW9xv4g8KE7d54XmMpNSgqLoOpgvC60xjNonnyc7p+VKamKw1zDCMR8rp8pksYlKorgAAIfkECQoAAAAsAAAAACsACwAAAkCEjgjLltnYmJS6Bxt+sfq5ZUyoNJ9HHlEqdCfFrqn7DrE2m7Wdj/2y45FkQ13t5itKdshFExC8YCLOEBX6AhQAADsAAAAAAAAAAAA=") no-repeat center center;
    width: 43px;
    height: 11px;
    margin-top: -5px;
    margin-left: -21px
}

.loading-alt--rel{
  width: 43px;
  height: 11px;
}

.loading--rel{
  width: 33px;
  height: 33px;
}

.loading {
    position: relative
}

.loading:before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    // z-index: 10;
    content: " ";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgc3R5bGU9ImZpbGw6ICNGRjc2NzciPg0KICA8cGF0aCBvcGFjaXR5PSIuMjUiIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMCAxNiAzMiBBMTYgMTYgMCAwIDAgMTYgMCBNMTYgNCBBMTIgMTIgMCAwIDEgMTYgMjggQTEyIDEyIDAgMCAxIDE2IDQiLz4NCiAgPHBhdGggZD0iTTE2IDAgQTE2IDE2IDAgMCAxIDMyIDE2IEwyOCAxNiBBMTIgMTIgMCAwIDAgMTYgNHoiPg0KICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE2IDE2IiB0bz0iMzYwIDE2IDE2IiBkdXI9IjAuOHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPg0KICA8L3BhdGg+DQo8L3N2Zz4=");
    height: 33px;
    width: 33px;
    margin-top: -15px;
    margin-left: -15px
}
// .loading:after {
//     position: absolute;
//     display: block;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background-color: #fff;
//     content: " ";
//     z-index: 9;
//     opacity: 0.9;
//     filter: alpha(opacity=90)
// }

.loading-2 {
  @extend .loading;
  position: static;
  &:before {
      position: static;
      height: 16px;
      width: 16px;
      background-size: 100% 100%;
      float:left;
      margin-left:6px;
      margin-top:2px;
  }
}