/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Component: Price
 * The size of this component depends on the font size of .price
 * Thus, if we need to make it bigger/smaller, just add font sizing class such as .fs1, .fs2, etc...
 * This is how to structure HTML for price
 *
 *  .price
 *    [role='currency']
 *    [role='price']
 *    [role='instant']
 *
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
.price {
  // background-color: #3c3f40;
  // background-color: rgba(60, 63, 64, 0.9);
  font-weight: bold;
  padding: 8px 12px;;
  // color: #fff;
  display: inline-block;
  @extend .fs1; // default font size

  [role='currency']{
    font-size: 65%;
    top: -0.5em;
    line-height: 0;
    position: relative;
    vertical-align: baseline;

    margin-right: 3px;
  }
}