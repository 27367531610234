/**
 * Color Constants
 */

$c-primary: #00B1BB;
$c-primary--active: #17868C; // make it darker
$c-primary--focus: #56D2D9; // make it lighter
$c-primary--border: $c-primary--active;

$c-secondary: #ff5a5f;
$c-secondary--active: #e00007; // make it darker
$c-secondary--focus: #ff7e82; // make it lighter

$c-green: #3DC463;
$c-green--active: #37b759; // make it darker
$c-green--focus: #54d354; // make it lighter
$c-green--border: $c-green--active;

$c-default: #FFFFFF;
$c-default--active: lighten($c-primary, 40%);
$c-default--focus: lighten($c-primary, 60%);
$c-default--border: lighten($c-primary, 20%);

$c-bg-focus: #f5f5f5;

$c-muted: #82888a;
