@font-face {
  font-family: dkrm-glyphs;
  src: url(https://media.dekoruma.com/fonts/dkrm-glyphs-237b332dd60adea1fb06a5d417d4da88.woff);
  src: url(https://media.dekoruma.com/fonts/dkrm-glyphs-ie8-5b36fb62a65ea2034d7fc0029b2ee864.eot?#)
      format(eot),
    url(https://media.dekoruma.com/fonts/dkrm-glyphs-237b332dd60adea1fb06a5d417d4da88.woff)
      format(woff),
    url(https://media.dekoruma.com/fonts/dkrm-glyphs-3b58feb48b7234776e5837bdac40a208.ttf)
      format(truetype),
    url(https://media.dekoruma.com/fonts/dkrm-glyphs-9ecf5e997ce1c13de9f802434c35c643.svg)
      format(svg);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url(https://media.dekoruma.com/fonts/CircularStd-Light.woff);
  src: url(https://media.dekoruma.com/fonts/CircularStd-Light.otf)
    format(opentype);
  src: url(https://media.dekoruma.com/fonts/CircularStd-Light.ttf)
    format(truetype);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url(https://media.dekoruma.com/fonts/CircularStd-Book.woff);
  src: url(https://media.dekoruma.com/fonts/CircularStd-Book.otf)
    format(opentype);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url(https://media.dekoruma.com/fonts/CircularStd-Medium.woff);
  src: url(https://media.dekoruma.com/fonts/CircularStd-Medium.otf)
    format(opentype);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url(https://media.dekoruma.com/fonts/CircularStd-Bold.woff);
  src: url(https://media.dekoruma.com/fonts/CircularStd-Bold.otf)
    format(opentype);
  font-weight: 700;
  font-style: normal;
}
