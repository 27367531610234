@import 'uikit-constants';


.menu{
  padding: 5px 0;
  list-style-type: none;
  margin: 0;
  li, .menu-item{
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    display: block;
    @extend .fs3;
    min-width: 90px;
  }
  .menu-link {
    color: inherit;
    outline: none;
    text-decoration: none;
    padding: 2px 24px;
    line-height: 22px;
    display: block;
    &:hover{
      background-color: #f5f5f5;
      color: $c-secondary;
    }
  }
  .menu-separator{
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    margin: 6px 0;
    padding-top: 1px;
  }
}

.menu--not-selectable{
  li, .menu-item{
    cursor: inherit;
  }
}
