:global {
  @import '../public/css/normalize'; // https://github.com/necolas/normalize.css/
  @import '../public/css/pure-grids';
  @import '../public/css/pure-grids-extension'; // custom extension, add more functionality
  @import '../public/css/pure-grids-responsive';
  @import '../public/css/pure-grids-responsive-hidden';
}

:global {
  @import '../public/css/uikit'; // custom utilities
  @import '../public/css/util'; // custom utilities
  @import url('https://unpkg.com/purecss@1.0.0/build/pure-min.css'); // custom utilities
  @import './reactSelect';
}

@import './fonts';

body {
  margin: 0;
  padding: 0;
}

input {
  outline: none;
}

a,
a:focus,
a:active {
  text-decoration: none;
}

:global {
  body {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  [type='text'],
  [type='password'],
  [type='search'],
  [type='email'],
  [type='url'],
  [type='number'],
  [type='tel'],
  textarea,
  select,
  input[type='radio'],
  input[type='checkbox'],
  .input-prefix,
  .input-suffix {
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom-width: 1px;
    background-color: unset !important;
  }

  [type='search'] {
    border: 0 !important;
  }

  .fade-exit {
    opacity: 1;
    z-index: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }
}
