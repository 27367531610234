/**
 * Adds gutter
 * http://stackoverflow.com/questions/24591365/best-way-to-extend-pure-csss-grid-to-have-gutters
 * 1. add padding to .pure-g .pure-u-*
 * 2. offset its parent margin's
 */

.pure-g.pure-g--gutter>[class *= "pure-u"] {
  padding-left: 12px;
  padding-right: 12px;
}
.pure-g.pure-g--gutter {
  margin-left: -12px;
  margin-right: -12px;
}


.pure-g.pure-g--gutter-s>[class *= "pure-u"] {
  padding-left: 2px;
  padding-right: 2px;
}
.pure-g.pure-g--gutter-s {
  margin-left: -2px;
  margin-right: -2px;
}

.pure-g.pure-g--v-gutter-s>[class *= "pure-u"] {
  padding-top: 2px;
  padding-bottom: 2px;
}
.pure-g.pure-g--v-gutter-s {
  margin-top: -2px;
  margin-bottom: -2px;
}
