@import 'uikit-constants';

.text-beach,
.icon-beach {
    color: #ffb400
}
.text-rausch,
.icon-rausch {
    color: #ff5a5f
}
.text-babu,
.icon-babu {
    color: $c-primary;
}
a.text-babu,
a.icon-babu {
  &:hover, &:focus{
    @extend .text-babu;
  }
}

.text-lima,
.icon-lima {
    color: #3fb34f
}

.text-ura,
.icon-ura {
  color: #2b6dad;
}
a.text-ura,
a.icon-ura {
  &:hover, &:focus{
    @extend .text-ura;
  }
}

.text-light-gray,
.icon-light-gray {
  color: #cacccd;
}

.text-white,
.icon-white {
  color: white;
}