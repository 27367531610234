.table{
  .td, .th {
    padding: 5px;
  }

  .th {
    background: #fff;
    padding: 5px;
    text-align: center;
    font-weight: bold;
  }

  &.border {
    .td {
      box-shadow: 1px 0 0 0 #e5e5e5,
        0 1px 0 0 #e5e5e5,
        1px 1px 0 0 #e5e5e5,
        1px 0 0 0 #e5e5e5 inset,
        0 1px 0 0 #e5e5e5 inset;
    }
    .th {
      box-shadow: 1px 0 0 0 #b9babb,
        0 1px 0 0 #b9babb,
        1px 1px 0 0 #b9babb,
        1px 0 0 0 #b9babb inset,
        0 1px 0 0 #b9babb inset;
      margin-bottom: 1px;
    }
  }

  .row {
    &:hover {
      box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
    }
  }
}